import { useContext, useState, useEffect } from 'react';

import get from 'lodash/get';
import set from 'lodash/set';
import noop from 'lodash/noop';

import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import Form, { FormContext } from '../helper/Form';
import { FormGroupNameContext } from '../helper/FormGroupName';

import { StorageFormCtx } from '../data/Storage';
import ComponentOutlet from '../storyblok/ComponentOutlet';
import StoryblokData, { StoryblokDataCtx } from '../storyblok/StoryblokData';
import stringifyCircular from '../../utils/stringifyCircular';
import template from '../../utils/template';

export function FormProvider({ name, children }) {
	const formData = useContext(FormContext);
	return <StoryblokData data={{ [name]: formData.form }}>{children}</StoryblokData>;
}

function StorageForm({
	body,
	debounce,
	initialState,
	name,
	storageType,
	map: mappedKeys,
	...props
}) {
	const { setStorage } = useContext(StorageFormCtx);
	const sbData = useContext(StoryblokDataCtx);
	const [subject] = useState(new BehaviorSubject());
	const formName = template(name, sbData);
	const storeType = storageType || 'localStorage';
	let init;
	const storedVal = window[storeType].getItem(formName);

	if (storedVal) init = JSON.parse(storedVal);
	if (!init) init = initialState === '_all' ? sbData : get(sbData, initialState);
	if (!init) init = {};
	const DEBOUNCE_TIME = Number(debounce) || 500;

	function onForm(state) {
		if (!DEBOUNCE_TIME) return;
		subject.next(state);
	}

	function onSubmit(event, { form }) {
		event.preventDefault();
		window[storeType].setItem(formName, stringifyCircular(form));
		setStorage((store) => {
			set(store, [storeType, formName], form);
			return store;
		});
	}

	useEffect(() => {
		if (!DEBOUNCE_TIME) return;
		const MIN_TIME = Math.max(DEBOUNCE_TIME, 500);
		const sub = subject
			.pipe(debounceTime(MIN_TIME))
			.subscribe((formState) => onSubmit({ preventDefault() {} }, formState), noop);

		return () => sub.unsubscribe();
		// eslint-disable-next-line
	}, [DEBOUNCE_TIME]);

	return (
		<Form {...props} as="div" initialState={init} needsValidation onForm={onForm} validateOnSubmit>
			<FormGroupNameContext.Provider value={{}}>
				<FormProvider name={formName}>
					<ComponentOutlet components={body} />
				</FormProvider>
			</FormGroupNameContext.Provider>
		</Form>
	);
}

StorageForm.defaultProps = {
	body: [],
	redirect: {},
};
export default StorageForm;
