import { createContext, useContext, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import get from 'lodash/get';
import uniqueId from 'lodash/uniqueId';

import { FormContext } from './Form';
import { FormGroupNameContext } from './FormGroupName';

export const FormGroupContext = createContext({
	name: '',
	controlId: '',
	error: '',
});

function FormGroup({ children, groupName, name: nameProp, controlId: propId, ...props }) {
	const formName = get(useContext(FormGroupNameContext), 'name');
	const formState = useContext(FormContext);
	const [selected, setSelected] = useState(false);
	const name = nameProp || [formName, groupName].filter(Boolean).join('.');
	const uId = Number(uniqueId()).toString(36);
	const [controlId, setControlId] = useState(propId || `${name}-${uId}`);
	const groupState = {
		controlId,
		error: formState.errors[name] || formState.errors[controlId],
		name,
		setControlId,
		selected,
		setSelected,
		uId,
	};

	useEffect(() => setControlId(`${name}-${uId}`), [name]);

	return (
		<FormGroupContext.Provider value={groupState}>
			<Form.Group {...props}>{children}</Form.Group>
		</FormGroupContext.Provider>
	);
}

export default FormGroup;
