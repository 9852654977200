import { useContext } from 'react';
import camelCase from 'lodash/camelCase';
import mapKeys from 'lodash/mapKeys';

import template from '../../utils/template';
import { StoryblokDataCtx } from '../storyblok/StoryblokData';

export default function Image({ src, style: sty, ...props }) {
	const sbData = useContext(StoryblokDataCtx);
	const style = mapKeys(sty, (v, k) => camelCase(k));

	return <img src={template(src, sbData)} style={style} {...props} />;
}
