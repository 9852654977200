import { useContext } from 'react';
import concat from 'lodash/concat';
import get from 'lodash/get';
import invoke from 'lodash/invoke';
import reduce from 'lodash/reduce';
import uniqueId from 'lodash/uniqueId';
import { FormContext } from '../helper/Form';
import ComponentOutlet from '../storyblok/ComponentOutlet';
import { StoryblokDataCtx } from '../storyblok/StoryblokData';
import template from '../../utils/template';

function tempUID() {
	return Number(new Date()).toString(36) + uniqueId();
}

function FormGroupToggle({
	name,
	body,
	className,
	initialData,
	type,
	onClick: propClick,
	...props
}) {
	const { form, setField, setForm } = useContext(FormContext);
	const sbData = useContext(StoryblokDataCtx);
	const groupName = template(name, sbData);

	function onClick(event) {
		if (propClick) propClick(event);
		invoke(sbData, 'setResponse', null);
		if (type === 'delete') return setField(groupName, '');

		const value = get(form, groupName);
		const mappedData = reduce(
			get(initialData, 'options'),
			(o, { name: k, value: v }) => Object.assign(o, { [k]: template(v, sbData) }),
			{}
		);
		const hasValues = Object.values(mappedData).length;
		const object = hasValues ? { uid: tempUID(), ...mappedData } : '';

		if (type === 'set') return setField(groupName, object);
		if (type === 'multi') return setForm((form) => Object.assign(form, mappedData));

		setField(groupName, concat(value || object, object));
	}

	return <ComponentOutlet className={className} components={body} onClick={onClick} />;
}
export default FormGroupToggle;
