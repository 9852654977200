import { useContext, useEffect } from 'react';
import Input from '../helper/Input';
import { FormContext } from '../helper/Form';

function SendEmail({ emailTemplate, to, ...props }) {
	const prefix = `_deliveryOptions.et_${emailTemplate}`;
	const formState = useContext(FormContext);

	useEffect(() => {
		return () => formState.setField(prefix, undefined);
	}, []);

	return (
		<>
			<Input type="hidden" name={`${prefix}.templateId`} value={emailTemplate} />
			<Input type="hidden" name={`${prefix}.prefix`} value={to} />
		</>
	);
}
export default SendEmail;
