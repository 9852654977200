// import { lazy } from 'react';
const manifest = {
	Button: require('./Button').default,
	CopyForm: require('./CopyForm').default,
	Dropzone: require('./Dropzone').default,
	ExternalForm: require('./ExternalForm').default,
	FormGroup: require('./FormGroup').default,
	FormGroupToggle: require('./FormGroupToggle').default,
	GroupName: require('./GroupName').default,
	Input: require('./Input').default,
	InputData: require('./InputData').default,
	LocalStorageForm: require('./LocalStorageForm').default,
	Option: require('./Option').default,
	SearchForm: require('./SearchForm').default,
	Select: require('./Select').default,
	SendEmail: require('./SendEmail').default,
	StorageForm: require('./StorageForm').default,
};

export default manifest;
