import BsModal from 'react-bootstrap/Modal';
import concat from 'lodash/concat';

import keyBy from 'lodash/keyBy';
import ComponentOutlet from '../storyblok/ComponentOutlet';
import useToggle, { ToggleContext } from '../hover/Toggle';
import { ContainerRefContext } from './ContainerRef';
import { useContext } from 'react';

function Modal({ body, toggle, className, size, state, ...props }) {
	const container = useContext(ContainerRefContext);
	const toggleCtx = useToggle();
	const toggles = concat(toggle).map((tg) => Object.assign(tg, { onClick: toggleCtx.show }));
	const opts = keyBy(state);

	if (opts.static)
		return (
			<BsModal size={size} show={true} backdrop="static" keyboard={false} container={container}>
				<ComponentOutlet components={body} />
			</BsModal>
		);

	return (
		<ToggleContext.Provider value={toggleCtx}>
			<ComponentOutlet components={toggles} className={className} {...props} />

			<BsModal size={size} show={toggleCtx.shown} onHide={toggleCtx.hide} container={container}>
				<ComponentOutlet components={body} />
			</BsModal>
		</ToggleContext.Provider>
	);
}
export default Modal;
