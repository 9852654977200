import Alert from 'react-bootstrap/Alert';
import ComponentOutlet from '../storyblok/ComponentOutlet';

function AlertBlok({ body, ...props }) {
	return (
		<Alert {...props}>
			<ComponentOutlet components={body} />
		</Alert>
	);
}
export default AlertBlok;
