import { useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import reduce from 'lodash/reduce';
import get from 'lodash/get';
import { StoryblokDataCtx } from './StoryblokData';
import template from '../../utils/template';

function RedirectCmp({ map: mappedKeys, url }) {
	const sbData = useContext(StoryblokDataCtx);
	const { linktype, cached_url } = url;

	useEffect(() => {
		if (cached_url.includes('outsourcerec://')) window.open(template(cached_url, sbData), '_self');
		if (cached_url.includes('https://')) window.location = template(cached_url, sbData);
		if (linktype !== 'story') window.location = template(cached_url, sbData);

		// eslint-disable-next-line
	}, [cached_url]);

	if (cached_url.includes('outsourcerec://')) return null;
	if (cached_url.includes('https://')) return null;

	if (linktype !== 'story') return null;

	if (cached_url.includes('/-')) {
		const mappedData = reduce(
			get(mappedKeys, 'options'),
			(o, { name: k, value: v }) => Object.assign(o, { [k]: template(v, sbData) }),
			{}
		);
		const urlTmp = cached_url
			.split('/')
			.map((v) => (v[0] === '-' ? `{{${v.substr(1)}}}` : v))
			.join('/');

		return <Redirect to={template(`/${urlTmp}`, mappedData)} />;
	}

	return <Redirect to={template(`/${cached_url}`, sbData)} />;
}
export default RedirectCmp;
