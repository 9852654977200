import { useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import axios from 'axios';
import get from 'lodash/get';
import set from 'lodash/set';

import Loader from '../helper/Loader';
import StoryblokData, { StoryblokDataCtx } from '../storyblok/StoryblokData';
import ComponentOutlet from '../storyblok/ComponentOutlet';
import chunkUpload from '../../utils/chunkUpload';
import { FormContext } from '../helper/Form';
import { template } from 'lodash';

// its 3MB, increase the number measure in mb
// const chunkSize = 1048576 * 3;

// Custom chunksizes for testing
function DropZone({ draggingArea, dropArea, name, path, uploaded, url, ...props }) {
	const sbData = useContext(StoryblokDataCtx);
	const form = useContext(FormContext);
	const [selectedKey, setSelectedKey] = useState();
	const endpoint = template(url, sbData);

	function onDrop(files) {
		form.setField(name, files);
	}
	const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

	return (
		<>
			<div data-cy="dropzone" {...getRootProps()}>
				<input {...getInputProps()} />
				{isDragActive && <ComponentOutlet components={draggingArea} />}
				{!isDragActive && <ComponentOutlet components={dropArea} />}
			</div>
			{acceptedFiles.map((file) => (
				<StoryblokData key={file.key || genKey(file)} data={{ [name]: file }}>
					<Uploader
						file={file}
						components={uploaded}
						selectedKey={selectedKey}
						setSelectedKey={setSelectedKey}
					/>
				</StoryblokData>
			))}
		</>
	);
}

function genKey(file) {}

function Uploader({ components, file, name, startUpload, next }) {
	const [srv, setSrv] = useState();
	const [progress, setProgress] = useState();

	useEffect(() => {
		if (srv) return;
		if (!startUpload) return;
		const headers = { 'Content-Type': 'application/octet-stream' };
		const service = axios
			.get(`/api/orgFiles/upload-signed/:file`)
			.then(({ data }) => axios.put(data.url, { data: file }, { headers, onUploadProgress }))
			.then(() => next());

		setSrv(service);

		function onUploadProgress(progressEvent) {
			setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
		}
	}, [file, setSrv]);

	if (srv)
		return (
			<Loader services={() => [srv]} required={[srv]} progress={progress}>
				{({ results: [origin], reload, setResults }) => {
					const data = get(origin, 'data');
					// const item = !path ? data : get(data, path);
					if (data) Object.assign(data, { reload, setResults, origin });

					return (
						<StoryblokData data={set({}, name, data)}>
							<ComponentOutlet components={components} />
						</StoryblokData>
					);
				}}
			</Loader>
		);

	return (
		<StoryblokData key={file.key || genKey(file)} data={set({}, name, file)}>
			<ComponentOutlet components={components} />
		</StoryblokData>
	);
}

DropZone.defaultProps = {
	method: 'post',
};

export default DropZone;
