import { createContext } from 'react';
import Loader from '../helper/Loader';
import { useLocation } from 'react-router-dom';
import get from 'lodash/get';
import autoCache from '../../api/autoCache';

import { sessionCache } from '../../setupAxios';
import getSession from '../../api/session';
import StoryblokPage from './StoryblokPage';
import StoryblokData from './StoryblokData';

const protectedDomains = {
	trioseo: false,
	outsourceschool: false,
};

export const website = window.location.host.split('.').slice(-2)[0];
export const protectedSite =
	protectedDomains[localStorage.website] ??
	protectedDomains[window.location.host] ??
	protectedDomains[website];

export const isProtected = localStorage.protected ?? protectedSite ?? true;

function getCookies() {
	return document.cookie.split('; ').reduce((o, pair) => {
		const [k, v] = pair.split('=');
		return Object.assign(o, { [k]: v });
	}, {});
}

export const SessionCtx = createContext();
function LoginGuard({ children }) {
	const { pathname } = useLocation();
	const isPublic = /^\/(?:p|layouts)\//i.test(pathname);

	if (!isProtected) {
		return children;
	}

	return (
		<Loader services={() => [getSession()]}>
			{({ results: [{ data }], reload: rld, setResults }) => {
				const user = get(data, 'passport.user') || get(data, 'user');
				const allow = isPublic || user;
				const isHome =
					window.location.href === `${window.location.origin}/index` ||
					window.location.href === `${window.location.origin}/`;

				if (!allow && !isHome) window.sessionStorage.setItem('_redirect', window.location.href);

				function reload() {
					sessionCache.cache.clear();
					rld();
				}

				return (
					<SessionCtx.Provider value={{ data, reload, setResults }}>
						<StoryblokData data={{ session: data }}>
							{allow && children}
							{allow && autoCache()}
							{!allow && <StoryblokPage page="sys/login" />}
						</StoryblokData>
					</SessionCtx.Provider>
				);
			}}
		</Loader>
	);
}

export default LoginGuard;
