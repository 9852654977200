import StoryblokPage from '../storyblok/StoryblokPage';

function Story({ story }) {
	const { linktype, cached_url } = story;
	if (linktype !== 'story') return null;
	return <StoryblokPage page={cached_url} />;
}
Story.defaultProps = { story: {} };

// const resolver = (prevProps, nextProps) =>
// 	prevProps.story.cached_url === nextProps.story.cached_url;
// export default React.memo(Story, resolver);
export default Story;
