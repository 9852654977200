import manifest from './manifest';
import StoryblokData from './StoryblokData';

const { Story } = manifest;
function StoryblokErrors(error) {
	return (
		<StoryblokData data={{ error, errorResponse: error }}>
			<Story story={{ linktype: 'story', cached_url: 'sys/error-messages' }} />
		</StoryblokData>
	);
}

export default StoryblokErrors;
