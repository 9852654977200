import { useContext } from 'react';
import { useLocation } from 'react-router';
import Card from 'react-bootstrap/Card';
import get from 'lodash/get';

import { PageContext } from './Page';
import { StoryblokPageCtx } from './StoryblokPage';
import ComponentOutlet from '../storyblok/ComponentOutlet';
import { StoryblokDataCtx } from './StoryblokData';

function LayoutContent() {
	const page = useContext(PageContext);
	const sbData = useContext(StoryblokDataCtx);
	const location = useLocation();
	const { page: story } = useContext(StoryblokPageCtx);
	const component = get(story, 'content.component');

	if (component === 'Layout' && !page.layout) {
		return (
			<Card>
				<Card.Body>
					<h2>The Content will be placed here when loaded.</h2>
				</Card.Body>
			</Card>
		);
	}

	if (!page) return null;

	if (window.organization !== sbData.organization) window.organization = sbData.organization;
	return <ComponentOutlet key={location.pathname} components={page.body} />;
}
export default LayoutContent;
