import { useContext } from 'react';

import Form from '../helper/Form';

import { FormProvider } from './ExternalForm';
import ComponentOutlet from '../storyblok/ComponentOutlet';
import { StoryblokDataCtx } from '../storyblok/StoryblokData';

function CopyForm({ url, state, body, ...props }) {
	const sbData = useContext(StoryblokDataCtx);

	function onSubmit(event, data) {
		console.log('onSubmit -> event, data', event, data);
	}

	return (
		<Form {...props} onSubmit={onSubmit} initialState={sbData.query} validateOnSubmit>
			<FormProvider>
				<ComponentOutlet components={body} />
			</FormProvider>
		</Form>
	);
}
export default CopyForm;
