import { createContext, useContext } from 'react';

export const StoryblokDataCtx = createContext({});
function StoryblokData({ children, data = {} }) {
	const sbData = useContext(StoryblokDataCtx) || {};
	return (
		<StoryblokDataCtx.Provider value={{ ...sbData, ...data }}>{children}</StoryblokDataCtx.Provider>
	);
}

export default StoryblokData;
