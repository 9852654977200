const manifest = {
	Alert: require('./Alert').default,
	Card: require('./Card').default,
	CardSection: require('./CardSection').default,
	Collapse: require('./Collapse').default,
	CollapseBody: require('./CollapseBody').default,
	CollapseToggle: require('./CollapseToggle').default,
	Column: require('./Column').default,
	Container: require('./Container').default,
	Dropdown: require('./Dropdown').default,
	Image: require('./Image').default,
	Link: require('./Link').default,
	Modal: require('./Modal').default,
	ModalContent: require('./ModalContent').default,
	ModalSection: require('./ModalContent').default,
	Nav: require('./Nav').default,
	Navbar: require('./Navbar').default,
	Row: require('./Row').default,
	Tab: require('./Tab').default,
	TabLink: require('./TabLink').default,
	Tabs: require('./Tabs').default,
	Tooltip: require('./Tooltip').default,
};

export default manifest;
