import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import concat from 'lodash/concat';
import ComponentOutlet from '../storyblok/ComponentOutlet';

function TooltipComponent({ body, message, name, placement }) {
	const hasMsg = Boolean(concat(message).length);
	return (
		<OverlayTrigger
			placement={placement}
			overlay={
				<Tooltip placement={placement}>
					{hasMsg && <ComponentOutlet components={message} />}
					{!hasMsg && name}
				</Tooltip>
			}
		>
			<div style={{ position: 'relative' }}>
				<ComponentOutlet components={body} />
			</div>
		</OverlayTrigger>
	);
}
TooltipComponent.defaultProps = { placement: 'auto' };
export default TooltipComponent;
