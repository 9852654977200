if (document.cookie.includes('bunny.token')) {
	fetch('/directus/auth/refresh', { method: 'POST', credentials: 'include' })
		.then((r) => r.json())
		.then((r) => r.data.access_token)
		.then((access_token) =>
			fetch('/directus/users/me', {
				headers: {
					Authorization: `Bearer ${access_token}`,
				},
			})
		)
		.then((r) => r.json())
		.then((resp) => {
			const token = resp?.data?.id;
			const exp = new Date();
			exp.setFullYear(exp.getFullYear() + 2);

			document.cookie = `bunny.token=${token}; expires=${exp}; path=/`;
		});
}
