import Modal from 'react-bootstrap/Modal';
import concat from 'lodash/concat';
import ComponentOutlet from '../storyblok/ComponentOutlet';

function ModalContent({ body, state, type, ...props }) {
	const stateProps = concat(state).reduce((o, k) => ({ ...o, [k]: true }), {});
	const SectionType = Modal[type || 'Body'];

	return (
		<SectionType {...stateProps} {...props}>
			{Boolean(stateProps.closeButton) && (
				<Modal.Title>
					<ComponentOutlet components={body} />
				</Modal.Title>
			)}
			{!stateProps.closeButton && <ComponentOutlet components={body} />}
		</SectionType>
	);
}
export default ModalContent;
