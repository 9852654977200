import { createContext, useState } from 'react';

export const StorageFormCtx = createContext({
	localStorage: {},
	sessionStorage: {},
	setStorage() {},
});

function Storage({ children }) {
	const [storage, setStorage] = useState({
		localStorage: {},
		sessionStorage: {},
	});

	return (
		<StorageFormCtx.Provider value={{ ...storage, setStorage }}>{children}</StorageFormCtx.Provider>
	);
}
export default Storage;
