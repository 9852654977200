import { useRef, useEffect } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { Container } from '@material-ui/core';

import { useHistory } from 'react-router';
import classnames from 'classnames';
import ComponentOutlet from '../storyblok/ComponentOutlet';
import useToggle, { ToggleContext } from '../hover/Toggle';

function SideNavLayout({ as, body, button, width, ...props }) {
	const ref = useRef();
	const history = useHistory();
	const toggleCtx = useToggle();

	useEffect(() => {
		history.listen(toggleCtx.hide);
		// eslint-disable-next-line
	}, []);

	return (
		<ToggleContext.Provider value={toggleCtx}>
			<ComponentOutlet as={as} components={button} onClick={toggleCtx.show} />
			<Drawer {...props} open={toggleCtx.shown} onClose={toggleCtx.hide} PaperProps={{ ref }}>
				<Container containerRef={ref}>
					<div
						style={{ width: width || 300, minHeight: '100%' }}
						className={classnames('m-0', { 'horizontal-drawer': true })}
						role="presentation"
					>
						<ComponentOutlet components={body} />
					</div>
				</Container>
			</Drawer>
		</ToggleContext.Provider>
	);
}
export default SideNavLayout;
