import { useParams } from 'react-router-dom';

import StoryblokData from './StoryblokData';
import StoryblokPage from './StoryblokPage';

function OrgDetector({ children }) {
	const params = useParams();
	if (params.org === 'client') {
		return (
			<StoryblokData data={{ subPage: window.location.pathname.replace('/o/client/', '') }}>
				<StoryblokPage page="index" />
			</StoryblokData>
		);
	}

	if (params.org !== '-org') {
		var d = new Date(Date.now() + 8.64e7);
		document.cookie = `storyblokOrg=${params.org}; expires=${d.toUTCString()};SameSite=lax`;
	}

	return <StoryblokData data={{ subPage: '' }}>{children}</StoryblokData>;
}

export default OrgDetector;
