import { useContext } from 'react';

import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';

import ComponentOutlet from '../storyblok/ComponentOutlet';
import { TabsCtx } from './Tabs';
import { FormContext } from '../helper/Form';

function TabLink({ body, eventKey, state: stateProp, ...props }) {
	const state = keyBy(stateProp);
	const { activeKey, navItems, setActiveKey } = useContext(TabsCtx) || {};
	const form = useContext(FormContext);

	function onClick() {
		if (!form || !state.validate) return selectTab();
		form.validate().then((hasError) => !find(hasError, Boolean) && selectTab());
	}

	function selectTab() {
		const selectedTab = find(navItems, { eventKey });
		if (!setActiveKey) return;
		if (selectedTab) return setActiveKey(selectedTab.eventKey);

		const tabIndex = findIndex(navItems, { eventKey: activeKey });
		const ek = { [eventKey]: true };
		let tabTo;
		if (ek['\\previous']) tabTo = tabIndex - 1;
		if (ek['\\next']) tabTo = tabIndex + 1;

		if (ek['\\previous'] && !tabIndex) return;
		if (ek['\\previous']) return setActiveKey(get(navItems, `${tabTo}.eventKey`));

		if (ek['\\next'] && tabIndex === navItems.length - 1) return;
		if (ek['\\next']) return setActiveKey(get(navItems, `${tabTo}.eventKey`));

		const n = eventKey.match(/\\(\d+)/i);
		// if (n && n[1]) return setActiveKey(get(navItems, `[1].eventKey`));
		if (n && n[1]) return setActiveKey(get(navItems, `${n[1]}.eventKey`));
	}

	return <ComponentOutlet components={body} onClick={onClick} />;
}
export default TabLink;
