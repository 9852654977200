import { useState, createContext } from 'react';
import concat from 'lodash/concat';
import ComponentOutlet from '../storyblok/ComponentOutlet';
import StoryblokData from '../storyblok/StoryblokData';

export const CollapseCtx = createContext();
function Collapse({ body, state }) {
	const stateProps = concat(state).reduce((o, k) => ({ ...o, [k]: true }), {});
	const [collapsed, setCollapsed] = useState(!stateProps.open);
	return (
		<CollapseCtx.Provider value={{ collapsed, setCollapsed }}>
			<StoryblokData data={{ collapsed }}>
				<ComponentOutlet components={body} />
			</StoryblokData>
		</CollapseCtx.Provider>
	);
}
export default Collapse;
