import { useContext, useState, useEffect } from 'react';

import concat from 'lodash/concat';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import forIn from 'lodash/forIn';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import lowerCase from 'lodash/lowerCase';
import matches from 'lodash/matches';
import noop from 'lodash/noop';
import reduce from 'lodash/reduce';

import axios from 'axios';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { FlashMessagesCtx } from '../helper/FlashMessages';
import { FormGroupNameContext } from '../helper/FormGroupName';
import { LeaveMessageCtx } from './LeaveMessage';
import { SessionCtx } from '../storyblok/LoginGuard';
import { ToggleContext } from '../hover/Toggle';
import ComponentOutlet from '../storyblok/ComponentOutlet';
import flattenCircular from '../../utils/flattenCircular';
import Form, { FormContext } from '../helper/Form';
import RecaptchaGaurd from '../storyblok/RecaptchaGaurd';
import Story from '../storyblok/Story';
import StoryblokData, { StoryblokDataCtx } from '../storyblok/StoryblokData';
import template from '../../utils/template';

function formChangeDetection(prev, curr) {
	return JSON.stringify(prev.form) === JSON.stringify(curr.form);
}

export function FormProvider({ error, response, children, ...rest }) {
	const formData = useContext(FormContext);
	return (
		<StoryblokData data={{ ...formData, ...rest, response, errorResponse: error }}>
			{children}
		</StoryblokData>
	);
}

function checkFiles(val) {
	const value = get(val, '0') || val;

	if (value instanceof Blob) return true;
	if (value instanceof File) return true;
}

function removeCircular(data) {
	const cache = new Map();
	const str = JSON.stringify(data, function (key, value) {
		if (typeof value === 'object' && value !== null) {
			// Duplicate reference found, discard key
			if (cache.has(value)) return;
			// Store value in our collection
			cache.set(value);
		}
		return value;
	});

	return JSON.parse(str);
}

const blankLink = matches({ linktype: 'story', id: '' });
function ExternalForm({
	action,
	body,
	errorMessages,
	initialState,
	leaveMessage,
	map: mappedKeys,
	method,
	parentField,
	redirect,
	redirectRoutes,
	successMessages,
	...props
}) {
	const sbData = useContext(StoryblokDataCtx);
	const session = useContext(SessionCtx);
	const toggle = useContext(ToggleContext);
	const { setLeaveMessage } = useContext(LeaveMessageCtx);
	const { setFlashMessage } = useContext(FlashMessagesCtx);

	const [error, setError] = useState();
	const [redirectCmp, setRedirect] = useState();
	const [response, setResponse] = useState();
	const [subject] = useState(new BehaviorSubject());

	const errorContent = get(errorMessages, 'cached_url');
	const successContent = get(successMessages, 'cached_url');
	const init = (initialState === '_all' ? sbData : get(sbData, initialState)) || {};
	const __parentForm = useContext(FormContext);
	if (!__parentForm.root) init.__parentForm = __parentForm;

	const DEBOUNCE_TIME = Number(redirect.cached_url);
	const successUrl = successContent || 'sys/success-messages';
	const errorUrl = 'sys/error-messages';

	function onForm(state) {
		if (leaveMessage) setLeaveMessage(leaveMessage);
		subject.next(state);
	}

	function onSubmit(event, { __preventRefresh, form: params, setSubmitting, history }) {
		event.preventDefault();
		let formData;
		const preventRefresh = __preventRefresh || params._autoSave;
		const actionUrl = template(action, { ...sbData, form: params });
		const organizationId = get(sbData, 'organizationId') || get(sbData, 'organization.id');

		if (DEBOUNCE_TIME) return;

		if (actionUrl.substr(0, 5) === 'form.') {
			const nameProp = template(props.name, { ...sbData, form: params });
			init.__parentForm.setField(actionUrl.substr(5), get(params, nameProp));
			return toggle && toggle.hide();
		}

		if (find(params, checkFiles)) {
			formData = new FormData();
			const flatParams = flattenCircular(params);
			forIn(flatParams, (v, k) => {
				if (isArray(v)) return forEach(v, (v) => formData.append(k, v));
				if (v && v.__filename) return formData.append(k, v, v && v.__filename);
				return formData.append(k, v);
			});
		}

		const payload = method === 'GET' ? { params } : params;
		const redir = redirect.linktype !== 'story' ? redirect.cached_url : `/${redirect.cached_url}`;
		setError(null);
		setResponse(null);
		setSubmitting(true);

		const headers = { 'organization-id': organizationId };
		if (formData) {
			headers['Content-Type'] = 'multipart/form-data';
		}

		axios[lowerCase(method || 'get')](actionUrl, formData || removeCircular(payload), { headers })
			.then(({ data: response }) => {
				const data = { ...sbData, form: params, response };
				const showMessages = params._autoSave || redir === 'message';

				if (!params._autoSave) {
					setFlashMessage(
						<StoryblokData data={{ response, actionUrl, method }}>
							<Story story={{ linktype: 'story', cached_url: successUrl }} />
						</StoryblokData>
					);
				}
				setLeaveMessage(null);

				if (preventRefresh && !successContent && !showMessages) {
					setError(null);
					setSubmitting(false);
					return toggle && toggle.hide();
				}

				if (parentField) {
					const nameProp = template(props.name, data);
					const pFieldName = template(parentField, { ...sbData, form: params });
					init.__parentForm.setField(pFieldName, get(data, nameProp));
					return toggle && toggle.hide();
				}

				if (showMessages || successContent || params._autoSave) {
					setResponse(response);
					setError(null);
					setSubmitting(false);
					return;
				}

				if (concat(redirectRoutes).filter(Boolean).length) {
					return setRedirect(data);
				}

				if (blankLink(redirect)) {
					setError(null);
					setSubmitting(false);
					toggle && toggle.hide();
					return session.reload();
				}

				const mappedData = reduce(
					get(mappedKeys, 'options'),
					(o, { name: k, value: v }) => Object.assign(o, { [k]: template(v, data) }),
					{}
				);
				const urlTmp = redir
					.split('/')
					.map((v) => (v[0] === '-' ? `{{${v.substr(1)}}}` : v))
					.join('/');

				if (actionUrl.includes('/passport')) session.reload();
				if (actionUrl.includes('/organization')) session.reload();
				const link = template(urlTmp, mappedData);
				history.push(link);
			})
			.catch((e) => {
				setSubmitting(false);

				const errorResponse = get(e, 'response.data') || e;
				setFlashMessage(
					<StoryblokData data={{ error: e, errorResponse, actionUrl, method }}>
						<Story story={{ linktype: 'story', cached_url: errorUrl }} />
					</StoryblokData>
				);
				setError(error);
			});
	}
	useEffect(() => {
		const sub = subject
			.pipe(debounceTime(5000), distinctUntilChanged(formChangeDetection))
			.subscribe((form) => {
				if (!get(form, 'form._autoSave')) return;
				form.onSubmit({ preventDefault() {} }, form);
			}, noop);

		return () => sub.unsubscribe();
	}, [subject]);

	useEffect(() => {
		if (!DEBOUNCE_TIME) return;
		const MIN_TIME = Math.max(DEBOUNCE_TIME, 500);
		const sub = subject.pipe(debounceTime(MIN_TIME)).subscribe(({ form: params }) => {
			const payload = method === 'GET' ? { params } : params;
			axios[lowerCase(method || 'get')](
				template(action, { ...sbData, form: params }),
				removeCircular(payload)
			);
		}, noop);

		return () => sub.unsubscribe();
		// eslint-disable-next-line
	}, [DEBOUNCE_TIME, action, method]);

	if (redirectCmp) {
		return (
			<StoryblokData data={redirectCmp}>
				<ComponentOutlet components={redirectRoutes} />
			</StoryblokData>
		);
	}

	return (
		<Form
			{...props}
			action={action}
			initialState={init}
			method={method}
			needsValidation
			onForm={onForm}
			onSubmit={onSubmit}
			validateOnSubmit
		>
			<FormGroupNameContext.Provider value={{}}>
				<FormProvider error={error} response={response} setResponse={setResponse}>
					{error && (
						<Story
							story={{ linktype: 'story', cached_url: errorContent || 'sys/error-messages' }}
						/>
					)}

					<RecaptchaGaurd>
						<ComponentOutlet components={body} />
					</RecaptchaGaurd>
				</FormProvider>
			</FormGroupNameContext.Provider>
		</Form>
	);
}

ExternalForm.defaultProps = {
	action: '',
	body: [],
	redirect: {},
};
export default ExternalForm;
