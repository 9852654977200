import { createContext } from 'react';
import { useParams, useHistory, useLocation } from 'react-router';
import { parse } from 'querystring-es3';

import get from 'lodash/get';
import ComponentOutlet from './ComponentOutlet';
import EditorDetector from './EditorDetector';
import getPage from '../../api/storyblok';
import Loader from '../helper/Loader';
import Page from '../storyblok/Page';
import StoryblokData from './StoryblokData';

function useQuery() {
	return parse(useLocation().search.substr(1));
}

const pageTypes = ['page', 'default-page', 'Page'];

export const StoryblokPageCtx = createContext({});
function StoryblokPage({ page }) {
	const history = useHistory();
	const params = useParams();
	const location = useLocation();
	const query = useQuery();

	return (
		<Loader services={() => [getPage(page)]} required={[page]} unmountOnExit={false}>
			{({ results, reload, setResults }) => {
				const [data] = results;
				const content = get(data, 'content');
				if (!content) return null;

				const { body, layout } = content;
				function setPage(newData) {
					const newRes = [{ ...results[0], data: newData }];
					setResults(newRes);
				}

				const isPage = pageTypes.includes(content.component);

				return (
					<StoryblokPageCtx.Provider value={{ page: data, reload, setPage }}>
						<EditorDetector />
						<StoryblokData
							data={{
								window,
								windowLocation: window.location,
								params,
								query,
								history,
								location,
							}}
						>
							{isPage && <Page full_slug={data.full_slug} body={body} layout={layout} />}
							{!isPage && <ComponentOutlet components={content} />}
						</StoryblokData>
					</StoryblokPageCtx.Provider>
				);
			}}
		</Loader>
	);
}

// const resolver = (prevProps, nextProps) => prevProps.page === nextProps.page;
// export default React.memo(StoryblokPage, resolver);
export default StoryblokPage;
