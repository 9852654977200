import { createContext, useState } from 'react';

import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';

import find from 'lodash/find';
import keyBy from 'lodash/keyBy';
import pickBy from 'lodash/pickBy';
import concat from 'lodash/concat';
import mapValues from 'lodash/mapValues';

import ComponentOutlet from '../storyblok/ComponentOutlet';

export const TabsCtx = createContext();
function Tabs({ body, variant, className, state, ...props }) {
	const stateProps = mapValues(keyBy(state), Boolean);
	const [navItems, setNavItems] = useState([]);
	const [activeKey, setActiveKey] = useState(null);

	function setNavItem(item) {
		setNavItems((navItems) => {
			const hasItem = find(navItems, { eventKey: item.eventKey });
			if (!navItems || !navItems.length) setActiveKey(item.eventKey);
			if (!hasItem) return concat(navItems, item);

			return navItems.map((navItem) => {
				if (navItem.eventKey !== item.eventKey) return navItem;
				return Object.assign(navItem, item);
			});
		});
	}

	const pickedKeys = pickBy({ activeKey }, Boolean);

	return (
		<Tab.Container {...pickedKeys} {...stateProps} {...props}>
			{!stateProps.noNav && (
				<Row>
					<Nav variant={variant} className={className}>
						{navItems.map((navItem) => (
							<Nav.Item>
								<Nav.Link
									as="span"
									onClick={() => {
										setActiveKey(navItem.eventKey);
									}}
									eventKey={navItem.eventKey}
								>
									<ComponentOutlet components={navItem.title} />
								</Nav.Link>
							</Nav.Item>
						))}
					</Nav>
				</Row>
			)}
			<Tab.Content>
				<TabsCtx.Provider value={{ navItems, setNavItems, setNavItem, activeKey, setActiveKey }}>
					<ComponentOutlet components={body} />
				</TabsCtx.Provider>
			</Tab.Content>
		</Tab.Container>
	);
}
export default Tabs;
