import { isObservable } from 'rxjs';

/* eslint-disable-next-line camelcase */
// import { observable as Symbol_observable } from 'rxjs/internal/symbol/observable';

export function isSubscribable(item) {
	if (!item) return false;

	return typeof isObservable(item) || Array.isArray(item) || item instanceof Promise;
}

export function util() {}
