import { useContext } from 'react';
import FormGroupName from '../helper/FormGroupName';
import template from '../../utils/template';
import ComponentOutlet from '../storyblok/ComponentOutlet';
import { StoryblokDataCtx } from '../storyblok/StoryblokData';

function FormGroup({ body, name, ...props }) {
	const sbData = useContext(StoryblokDataCtx);
	return (
		<FormGroupName name={template(name, sbData)} {...props}>
			<ComponentOutlet components={body} />
		</FormGroupName>
	);
}
export default FormGroup;
