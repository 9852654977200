import { createContext, useContext } from 'react';
import BsCard from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

import concat from 'lodash/concat';
import ComponentOutlet from '../storyblok/ComponentOutlet';
import template from '../../utils/template';
import { StoryblokDataCtx } from '../storyblok/StoryblokData';

export const CardCtx = createContext({});
function Card({ contents, text, state, activeKey, ...props }) {
	const sbData = useContext(StoryblokDataCtx);
	const stateProps = concat(state).reduce((o, k) => ({ ...o, [k]: true }), {});
	const txtColor = text.replace('text-', '');
	if (stateProps.accordion) {
		const actKy = template(activeKey, sbData) || undefined;
		return (
			<Accordion defaultActiveKey={actKy}>
				<CardCtx.Provider value={stateProps}>
					<BsCard text={txtColor} {...stateProps} accordion={undefined} {...props}>
						<ComponentOutlet components={contents} />
					</BsCard>
				</CardCtx.Provider>
			</Accordion>
		);
	}

	return (
		<BsCard text={txtColor} {...stateProps} {...props}>
			<CardCtx.Provider value={{}}>
				<ComponentOutlet components={contents} />
			</CardCtx.Provider>
		</BsCard>
	);
}

Card.defaultProps = {
	text: '',
};
export default Card;
