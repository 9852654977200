import { Component } from 'react';
import { findDOMNode } from 'react-dom';

class ReactComment extends Component {
	state = { show: Boolean(this.props.comment && window.location.href.includes('_storyblok=')) };

	componentDidMount() {
		if (!this.props.comment) return;

		const element = findDOMNode(this);
		setTimeout(() => {
			try {
				const comStr = this.props.comment.replace(/^<!--/, '').replace(/-->$/, '');
				this.comment = document.createComment(comStr);
				element.parentNode.insertBefore(this.comment, element);
			} catch (e) {}
			this.setState({ show: false });
		}, 100);
	}

	componentWillUnmount() {
		try {
			this.comment.parentNode.removeChild(this.comment);
		} catch (e) {}
	}

	render() {
		if (!this.state.show) return null;

		return this.state.show && <div />;
	}
}

export default ReactComment;
