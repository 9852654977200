import { createContext, useContext, useEffect, useState } from 'react';
import classnames from 'classnames';
import concat from 'lodash/concat';
import get from 'lodash/get';
import FormGroup, { FormGroupContext } from '../helper/FormGroup';
import Feedback from '../helper/Feedback';
import Label from '../helper/Label';
import Input from '../helper/Input';

import validators from '../../validation';
import ComponentOutlet from '../storyblok/ComponentOutlet';
import InputGroup from 'react-bootstrap/InputGroup';
import template from '../../utils/template';
import StoryblokData, { StoryblokDataCtx } from '../storyblok/StoryblokData';

export function detectText(cmp) {
	if (cmp.component === 'rich-text-field') cmp.as = InputGroup.Text;
	if (cmp.component === 'text-block') cmp.as = InputGroup.Text;
}

function LabelCmp({ label, label_text, children, ...props }) {
	const formGroupState = useContext(FormGroupContext);
	const selected = formGroupState && formGroupState.selected;

	return (
		Boolean(concat(label).length || label_text) && (
			<Label {...props}>
				{!String(label) && label_text}
				{children}
				<StoryblokData data={{ selected }}>
					<ComponentOutlet components={label} />
				</StoryblokData>
			</Label>
		)
	);
}

export const SelectCtx = createContext();
function SelectCmp({ value, ...props }) {
	return (
		<SelectCtx.Provider value={value}>
			<select value={value} {...props} />
		</SelectCtx.Provider>
	);
}

function Select({
	append,
	body,
	className,
	label_text,
	label,
	name,
	placeholder,
	prepend,
	type,
	value,
	validations: vCmps,
	as: asProp,
	...props
}) {
	const [validations, setValidations] = useState([]);
	const isGroup = Boolean(get(prepend, 'length') || get(append, 'length'));
	const sbData = useContext(StoryblokDataCtx);

	if (get(prepend, 'length')) prepend.forEach(detectText);
	if (get(append, 'length')) append.forEach(detectText);

	useEffect(() => {
		if (!vCmps || !vCmps.length) return;

		const validations = vCmps.map((cmp) => {
			const name = cmp.component.replace('validation-', '').replace('Validation', '');
			return validators[name](cmp);
		});
		setValidations(validations);
	}, [vCmps]);

	return (
		<FormGroup
			groupName={template(name, sbData)}
			className={classnames(className, {
				'd-none': type === 'hidden',
				'mb-0': !concat(label).length && !placeholder,
			})}
		>
			<LabelCmp label={label} label_text={label_text} />

			{isGroup && (
				<InputGroup>
					{Boolean(prepend.length) && (
						<InputGroup.Prepend>
							<ComponentOutlet components={prepend} />
						</InputGroup.Prepend>
					)}
					<Input
						{...props}
						className="form-control"
						as={SelectCmp}
						label={<LabelCmp label={label} label_text={label_text} />}
						onClick={(e) => e.stopPropagation()}
						placeholder={template(placeholder, sbData)}
						type={type}
						validations={validations}
						value={template(value, sbData)}
					>
						<ComponentOutlet components={body} />
					</Input>
					{Boolean(append.length) && (
						<InputGroup.Append>
							<ComponentOutlet components={append} />
						</InputGroup.Append>
					)}
					<Feedback />
				</InputGroup>
			)}

			{!isGroup && (
				<Input
					{...props}
					as={SelectCmp}
					className={classnames(className, 'form-control')}
					label={<LabelCmp label={label} label_text={label_text} />}
					onClick={(e) => e.stopPropagation()}
					placeholder={template(placeholder, sbData)}
					type={type}
					validations={validations}
					value={template(value, sbData)}
				>
					<ComponentOutlet components={body} />
				</Input>
			)}

			<Feedback />
		</FormGroup>
	);
}
export default Select;
