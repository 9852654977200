import { createContext, useContext, useEffect, Children, useRef, useState } from 'react';
import classnames from 'classnames';
import concat from 'lodash/concat';
import get from 'lodash/get';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import InputMask from 'react-input-mask';

import ComponentOutlet from '../storyblok/ComponentOutlet';
import Feedback from '../helper/Feedback';
import FormGroup, { FormGroupContext } from '../helper/FormGroup';
import Input from '../helper/Input';
import Label from '../helper/Label';
import StoryblokData, { StoryblokDataCtx } from '../storyblok/StoryblokData';
import template from '../../utils/template';
import validators from '../../validation';

export const InputCtx = createContext({ current: null });
export const textComponents = ['rich-text-field', 'text-block'];
export function detectText(cmp) {
	if (textComponents.includes(cmp.component)) cmp.as = InputGroup.Text;
	return cmp;
}

const checkboxes = ['checkbox', 'radio'];
const btns = ['Link', 'Button', 'ExternalForm'];
export function isBtn(body) {
	return btns.includes(get(body, '0.component'));
}

function LabelCmp({ label, label_text, children, ...props }) {
	const formGroupState = useContext(FormGroupContext);
	const selected = formGroupState && formGroupState.selected;

	return (
		Boolean(concat(label).length || label_text) && (
			<Label {...props}>
				{!String(label) && label_text}
				{children}
				<StoryblokData data={{ selected }}>
					<ComponentOutlet components={label} />
				</StoryblokData>
			</Label>
		)
	);
}

function InputBlok({
	append,
	as: asProp,
	className,
	label_text,
	label,
	name,
	placeholder,
	prepend,
	rounded,
	type,
	validations: vCmps,
	value,
	...props
}) {
	const [validations, setValidations] = useState([]);
	const sbData = useContext(StoryblokDataCtx);
	const ref = useRef();
	const isGroup = Boolean(get(prepend, 'length') || get(append, 'length'));
	const isSwitch = type === 'switch';
	// const GroupAs = isSwitch ? LabelCmp : 'div';
	let As = isSwitch ? Form.Check : asProp;

	const textArea = type === 'textarea' || type === 'area';
	const classUtil = { 'form-control': props.mask || textArea };
	const isCheckbox = checkboxes.includes(type);

	if (textArea) As = 'textarea';
	if (props.mask) As = InputMask;
	if (!isSwitch && get(prepend, 'length')) prepend.forEach(detectText);
	if (!isSwitch && get(append, 'length')) append.forEach(detectText);

	useEffect(() => {
		if (!vCmps || !vCmps.length) return;

		const newValidations = vCmps.map((cmp) => {
			const name = cmp.component.replace('validation-', '').replace('Validation', '');

			return validators[name](cmp);
		});

		setValidations(newValidations);
	}, [vCmps]);

	if (type === 'checkbox-required') {
		return (
			<InputCtx.Provider value={ref}>
				<FormGroup groupName={template(name, sbData)} className="was-validated">
					<LabelCmp label={label} label_text={label_text} className="d-flex align-items-center">
						<Input
							{...props}
							ref={ref}
							as={As}
							useId={false}
							onClick={(e) => e.stopPropagation()}
							placeholder={template(placeholder, sbData)}
							className="mx-2"
							type="checkbox"
							required
							validations={validations}
							value={template(value, sbData)}
						/>
					</LabelCmp>
					<Feedback className="d-block" />
				</FormGroup>
			</InputCtx.Provider>
		);
	}

	if (type.includes('-hidden')) {
		const nType = type.replace('-hidden', '');
		return (
			<InputCtx.Provider value={ref}>
				<FormGroup
					groupName={template(name, sbData)}
					className={classnames(className, {
						'd-none': type === 'hidden',
						'mb-0': !concat(label).length && !placeholder,
					})}
				>
					<LabelCmp label={label} label_text={label_text} className="d-block">
						<Input
							{...props}
							ref={ref}
							className="sr-only"
							as={As}
							onClick={(e) => e.stopPropagation()}
							placeholder={template(placeholder, sbData)}
							type={nType}
							validations={validations}
							value={template(value, sbData)}
						/>
					</LabelCmp>
				</FormGroup>
			</InputCtx.Provider>
		);
	}

	return (
		<InputCtx.Provider value={ref}>
			<FormGroup
				groupName={template(name, sbData)}
				className={classnames(className, {
					'd-none': type === 'hidden',
					'mb-0': !concat(label).length && !placeholder,
				})}
			>
				{!isCheckbox && !isSwitch && <LabelCmp label={label} label_text={label_text} />}

				{isGroup && (
					<InputGroup>
						{Boolean(prepend.length) && (
							<InputGroup.Text>
								<ComponentOutlet components={prepend} />
							</InputGroup.Text>
						)}

						<Input
							{...props}
							ref={ref}
							className={classnames(rounded, classUtil)}
							as={As}
							label={<LabelCmp label={label} label_text={label_text} />}
							onClick={(e) => e.stopPropagation()}
							placeholder={template(placeholder, sbData)}
							type={type}
							validations={validations}
							value={template(value, sbData)}
						/>

						{!isCheckbox && !isBtn(append) && Boolean(append.length) && (
							<InputGroup.Text>
								<ComponentOutlet components={append} />
							</InputGroup.Text>
						)}
						{isBtn(append) && <ComponentOutlet components={append} />}

						<Feedback />
					</InputGroup>
				)}

				{!isGroup && (
					<Input
						{...props}
						ref={ref}
						className={classnames(className, rounded, classUtil)}
						as={As}
						label={<LabelCmp label={label} label_text={label_text} />}
						onClick={(e) => e.stopPropagation()}
						placeholder={template(placeholder, sbData)}
						type={type}
						validations={validations}
						value={template(value, sbData)}
					/>
				)}

				{isCheckbox && <LabelCmp label={label} label_text={label_text} />}
				{isCheckbox && <ComponentOutlet components={append} />}

				<Feedback />
			</FormGroup>
		</InputCtx.Provider>
	);
}
InputBlok.defaultProps = {
	type: '',
};
export default InputBlok;
