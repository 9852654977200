import { BrowserRouter as Router } from 'react-router-dom';
import manifest from './components/manifest';

import { LoaderCtx } from './components/helper/Loader';
import ComponentManifest from './components/ComponentManifest';
import FlashMessages from './components/helper/FlashMessages';
import HttpError from './components/storyblok/HttpError';
import LeaveMessage from './components/form/LeaveMessage';
import Storage from './components/data/Storage';
import StoryblokRoutes from './components/storyblok/StoryblokRoutes';

function onError(error, reload) {
	return <HttpError error={error} reload={reload} />;
}

function App() {
	return (
		<LoaderCtx.Provider value={{ onError, timeout: 1000 }}>
			<ComponentManifest manifest={manifest}>
				<Router>
					<FlashMessages>
						<LeaveMessage>
							<Storage>
								<StoryblokRoutes />
							</Storage>
						</LeaveMessage>
					</FlashMessages>
				</Router>
			</ComponentManifest>
		</LoaderCtx.Provider>
	);
}

export default App;
