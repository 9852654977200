import { createContext } from 'react';
import Form from 'react-bootstrap/Form';

export const FormGroupNameContext = createContext({
	name: '',
	controlId: '',
	error: '',
});

function FormGroupName({ children, name, ...props }) {
	return (
		<FormGroupNameContext.Provider value={{ name }}>
			<Form.Group {...props}>{children}</Form.Group>
		</FormGroupNameContext.Provider>
	);
}

export default FormGroupName;
