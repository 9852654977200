import { useContext } from 'react';
import Loader from '../helper/Loader';
import axios from 'axios';
import buildUrl from 'axios/lib/helpers/buildURL';

import concat from 'lodash/concat';
import fromPairs from 'lodash/fromPairs';
import get from 'lodash/get';
import isString from 'lodash/isString';
import keyBy from 'lodash/keyBy';
import reduce from 'lodash/reduce';
import set from 'lodash/set';

import ComponentOutlet from '../storyblok/ComponentOutlet';
import RecaptchaGaurd from '../storyblok/RecaptchaGaurd';
import StoryblokData, { StoryblokDataCtx } from '../storyblok/StoryblokData';
import template from '../../utils/template';
import { SessionCtx } from '../storyblok/LoginGuard';

function jsonp(url, data) {
	const script = document.createElement('script');
	const callback = `cb${Number(new Date())}`;
	script.src = buildUrl(url, { ...data, callback });
	const resolver = new Promise(function (resolve, onError) {
		window[callback] = (data) => resolve({ callback, data });
		script.onerror = onError;
	});

	document.head.appendChild(script);
	return resolver;
}

function BasicData({
	body,
	data,
	method = 'get',
	name,
	no_data,
	path,
	state,
	testUrl,
	url,
	...props
}) {
	const sbData = useContext(StoryblokDataCtx);
	const session = useContext(SessionCtx);
	const stateProps = keyBy(state);
	const noData = concat(no_data).filter(Boolean).length ? no_data : body;
	const endpoint = get(sbData, 'query._storyblok') && testUrl ? testUrl : template(url, sbData);
	const loaderConfig = {};
	const mappedData = reduce(
		get(data, 'options'),
		(o, { name: k, value: v }) => Object.assign(o, { [template(k, sbData)]: template(v, sbData) }),
		{}
	);

	if (stateProps.noRetry) loaderConfig.onError = () => null;

	if (!url && path) {
		const getData = (path) => get(sbData, template(path, sbData));
		const condPath = path.includes('||') && path.split(/\s*\|\|\s*/).find(getData);
		const tempPath = template(condPath || path, sbData);
		const item = get(sbData, tempPath);

		return (
			<StoryblokData data={set({}, name, item)}>
				{!item && <ComponentOutlet components={noData} />}
				{Boolean(item) && <ComponentOutlet components={body} />}
			</StoryblokData>
		);
	}

	function services() {
		if (method === 'jsonp') return [jsonp(endpoint, mappedData)];

		const organizationId =
			get(sbData, 'organizationId') ||
			get(sbData, 'organization.id') ||
			get(window, 'organization.id');

		const headers = { 'organization-id': organizationId };
		if (method === 'get' && !endpoint.includes('http')) mappedData.headers = headers;

		const xhrCall = axios[method](endpoint, mappedData, { headers }).then(function (resp) {
			const isHTML = resp.headers['content-type'].includes('text/html');
			if (isString(resp.data) && !isHTML && resp.data.includes('=')) {
				resp.data = resp.data.split('\n').map((s) => s.split('='));
				resp.data = fromPairs(resp.data);
			}
		});

		return [axios[method](endpoint, mappedData, { headers })];
	}

	return (
		<Loader {...loaderConfig} services={services} required={[endpoint, get(session, 'data.init')]}>
			{({ results: [origin], reload, setResults }) => {
				let data = get(origin, 'data');

				const item = !path ? data : get(data, path);
				if (item) Object.assign(item, { reload, setResults, origin: () => origin });

				return (
					<StoryblokData data={set({}, name, item)}>
						{!item && <ComponentOutlet components={noData} />}
						{Boolean(item) && <ComponentOutlet components={body} />}
					</StoryblokData>
				);
			}}
		</Loader>
	);
}

BasicData.defaultProps = {
	body: [],
};

export default function setup(props) {
	if (!props.method || props.method === 'get') return <BasicData {...props} />;

	return (
		<RecaptchaGaurd>
			<BasicData {...props} />
		</RecaptchaGaurd>
	);
}
