import { useContext, useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import uniqueId from 'lodash/uniqueId';

import ComponentOutlet from '../storyblok/ComponentOutlet';
import { TabsCtx } from './Tabs';

function Tabs({ body, name, ...props }) {
	const { setNavItem } = useContext(TabsCtx);
	const [eventKey, setEventKey] = useState(name);
	useEffect(() => {
		const eventKey = name || uniqueId();
		if (!name) setEventKey(eventKey);
		setNavItem({ ...props, eventKey });
		// eslint-disable-next-line
	}, []);

	return (
		<Tab.Pane mountOnEnter eventKey={eventKey} {...props}>
			<ComponentOutlet components={body} />
		</Tab.Pane>
	);
}
export default Tabs;
