import Timer from './Timer';
import CircularProgress from '@material-ui/core/CircularProgress';

function Fallback() {
	return (
		<Timer>
			<CircularProgress />
		</Timer>
	);
}

export default Fallback;
