import Row from 'react-bootstrap/Row';
import ComponentOutlet from '../storyblok/ComponentOutlet';

export default function RowCmp({ body, ...props }) {
	return (
		<Row {...props}>
			<ComponentOutlet components={body} />
		</Row>
	);
}
