import { useContext } from 'react';
import Loader from '../helper/Loader';
import axios from 'axios';

import get from 'lodash/get';
import set from 'lodash/set';
import concat from 'lodash/concat';
import sortBy from 'lodash/sortBy';

import ComponentOutlet from '../storyblok/ComponentOutlet';
import StoryblokData, { StoryblokDataCtx } from '../storyblok/StoryblokData';
import template from '../../utils/template';
import { SessionCtx } from '../storyblok/LoginGuard';

function BasicRepeater({ name, path, body, url, no_data, limit, ...props }) {
	const $data = useContext(StoryblokDataCtx);
	const session = useContext(SessionCtx);
	const noData = concat(no_data);

	if (!url) {
		let parent = concat(get($data, path)).filter(Boolean);
		if (props.sortBy) parent = sortBy(parent, props.sortBy);
		parent.map((item, index) => Object.assign(item, { index: index || String(index) }));

		if (!parent.length && noData.length) {
			return (
				<StoryblokData data={{ [name]: { index: '0' } }}>
					<ComponentOutlet components={noData} />
				</StoryblokData>
			);
		}

		return parent.slice(0, limit || parent.length).map((item, index) => (
			<StoryblokData key={item.id || index} data={set({}, name, item)}>
				<ComponentOutlet components={body} prefix={item.id || index} />
			</StoryblokData>
		));
	}

	return (
		<Loader
			services={() => [axios.get(template(url, $data))]}
			require={[get(session, 'data.init')]}
		>
			{({ results: [origin], reload, setResults }) => {
				const data = get(origin, 'data');
				let parent = concat(!path ? data : get(data, path)).filter(Boolean);
				if (props.sortBy) parent = sortBy(parent, props.sortBy);

				Object.assign(parent, { reload, setResults, origin: () => origin });
				parent.map((item, index) => Object.assign(item, { parent, index: index || String(index) }));

				if (!parent.length && noData.length) {
					return (
						<StoryblokData data={{ [name]: { index: '0' } }}>
							<ComponentOutlet components={noData} />
						</StoryblokData>
					);
				}

				return parent.slice(0, limit || parent.length).map((item, index) => (
					<StoryblokData key={item.id || index} data={set({}, name, item)}>
						<ComponentOutlet components={body} prefix={item.id || index} />
					</StoryblokData>
				));
			}}
		</Loader>
	);
}

export default BasicRepeater;
