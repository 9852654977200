import { useContext } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import { CollapseCtx } from './Collapse';
import ComponentOutlet from '../storyblok/ComponentOutlet';

function CollapseToggle({ body, height, ...props }) {
	const { collapsed } = useContext(CollapseCtx);
	const mountOnEnter = !height && true;
	const hght = collapsed && height;
	const style = height
		? {
				display: 'block',
				maxHeight: hght,
				minHeight: height,
				overflowX: 'auto',
				overflowY: 'scroll',
		  }
		: {};

	return (
		<Collapse in={!collapsed} mountOnEnter={mountOnEnter}>
			<div {...props} style={style}>
				<ComponentOutlet components={body} />
			</div>
		</Collapse>
	);
}
export default CollapseToggle;
