import { createContext, useContext } from 'react';
import { FormGroupContext } from './FormGroup';

export const LabelFormContext = createContext({ labelFor: '', setLabelFor() {} });
function Label({ children, ...props }) {
	const groupState = useContext(FormGroupContext);

	return (
		<label htmlFor={groupState.controlId} {...props}>
			{children}
		</label>
	);
}

export default Label;
