import { useContext, useEffect } from 'react';
import classnames from 'classnames';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import get from 'lodash/get';

import { StoryblokDataCtx } from '../storyblok/StoryblokData';
import template from '../../utils/template';

function TextBlock({ as, body, text, textAlign, textWrap, className, ...props }) {
	const AS = as;
	const sbData = useContext(StoryblokDataCtx);
	const bs5 = String(textAlign).replace('left', 'start').replace('right', 'end');
	const html = body.replace(/<script .+<\/script>/g, '');

	useEffect(() => {
		const urlGroups = body.match(/<script src="(.+)"/i);
		const src = get(urlGroups, '1');
		if (!src || !src.includes('//')) return;

		if (document.querySelector(`[src="${src}"]`)) return;

		const script = document.createElement('script');
		script.src = src;
		document.head.appendChild(script);
	}, [body]);

	if (!html) return <div />;

	if (AS) return <AS as={ReactMarkdown} escapeHtml={false} source={template(html, sbData)} />;
	const source = template(html, sbData);

	return (
		<ReactMarkdown
			children={source}
			className={classnames(className, text, bs5, textWrap || 'text-break', 'rmd')}
			escapeHtml={false}
			key={source}
			rehypePlugins={[rehypeRaw]}
			transformLinkUri={(url) => url}
		/>
	);
}
export default TextBlock;
