import { useContext, useEffect } from 'react';
import get from 'lodash/get';

import { FormContext } from '../helper/Form';
import { StoryblokDataCtx } from '../storyblok/StoryblokData';
import template from '../../utils/template';

function InputData({ path, name }) {
	const sbData = useContext(StoryblokDataCtx);
	const { form, setField } = useContext(FormContext);
	const key = template(name, sbData);
	const value = get(sbData, template(path, sbData));

	useEffect(() => {
		setField(key, value);
	}, [value]);

	return null;
}
export default InputData;
