import { useContext } from 'react';
import isArray from 'lodash/isArray';
import camelCase from 'lodash/camelCase';
import mapKeys from 'lodash/mapKeys';

import { StoryblokDataCtx } from '../storyblok/StoryblokData';
import ComponentOutlet from '../storyblok/ComponentOutlet';
import template from '../../utils/template';

function Container({ body, className, height, width, style, bsSize, name, ...props }) {
	const sbData = useContext(StoryblokDataCtx);
	const sty = isArray(style && style.options)
		? style.options.reduce((o, { name, value }) => ({ ...o, [name]: value }), {})
		: style;

	return (
		<div
			{...props}
			name={template(name, sbData)}
			className={className}
			style={mapKeys({ ...sty, height, width }, (v, k) => camelCase(k))}
		>
			<ComponentOutlet components={body} />
		</div>
	);
}
export default Container;
