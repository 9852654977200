import { useContext } from 'react';
import FormGroupNameContext from '../helper/FormGroupName';
import template from '../../utils/template';
import ComponentOutlet from '../storyblok/ComponentOutlet';
import { StoryblokDataCtx } from '../storyblok/StoryblokData';

function GroupName({ body, name }) {
	const sbData = useContext(StoryblokDataCtx);
	return (
		<FormGroupNameContext.Provider value={{ name: template(name, sbData) }}>
			<ComponentOutlet components={body} />
		</FormGroupNameContext.Provider>
	);
}
export default GroupName;
