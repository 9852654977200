import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import useToggle from '../hover/Toggle';

import ComponentOutlet from '../storyblok/ComponentOutlet';

function TransitionUp(props) {
	return <Slide {...props} direction="down" />;
}

function SnackAlert({ autoHideDuration, body, variant, ...props }) {
	const toggleCtx = useToggle(true);

	function handleClose(event, reason) {
		if (reason === 'clickaway') return;
		toggleCtx.hide();
	}

	return (
		<Snackbar
			autoHideDuration={Number(autoHideDuration) || 6000}
			{...props}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			TransitionComponent={TransitionUp}
			open={toggleCtx.shown}
			onClose={handleClose}
		>
			<div>
				<ComponentOutlet components={body} />
			</div>
		</Snackbar>
	);
}
export default SnackAlert;
