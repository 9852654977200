import { useContext } from 'react';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';

// import ComponentOutlet from '../storyblok/ComponentOutlet';
import template from '../../utils/template';
import { StoryblokDataCtx } from '../storyblok/StoryblokData';
import { SelectCtx } from './Select';
function Option({ name, value, state, price, ...props }) {
	const sbData = useContext(StoryblokDataCtx);
	const selected = useContext(SelectCtx);
	const val = template(value, sbData);
	const stateKeys = mapValues(keyBy(state), Boolean);

	return (
		<option {...stateKeys} value={val} data-price={price} selected={selected === val} {...props}>
			{template(name, sbData)}
		</option>
	);
}
export default Option;
