import template from '../utils/template';

export default function required({ message }, sbData) {
	return (name, value, form) => {
		const data = { ...sbData, form, value };
		if (Array.isArray(value) && !value.length) return template(message, data);
		if (!value) return template(message, data);
		if (!String(value).replace(/\s+/g, '')) return template(message, data);
	};
}
