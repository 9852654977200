import { useEffect, useRef, useState } from 'react';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import Divider from '@material-ui/core/Divider';
import ComponentOutlet from '../storyblok/ComponentOutlet';

function DividerCmp({ body, state, ...props }) {
	const ref = useRef();
	const [top, setTop] = useState(get(ref, 'current.clientHeight', 0) / -2);
	const stateProps = keyBy(state);
	useEffect(() => {
		setTop(get(ref, 'current.clientHeight', 0) / -2);
	}, [setTop, ref]);

	if (!body.length) return <Divider {...props} {...stateProps} />;

	return (
		<div style={{ position: 'relative' }}>
			<Divider {...props} {...stateProps} />
			<div ref={ref} style={{ position: 'absolute', top, width: '100%' }}>
				<ComponentOutlet components={body} />
			</div>
		</div>
	);
}
export default DividerCmp;
