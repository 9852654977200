import { useContext } from 'react';
import { CollapseCtx } from './Collapse';
import Button from '../form/Button';

function CollapseToggle({ button }) {
	const { setCollapsed } = useContext(CollapseCtx);

	return (
		<Button
			{...button[0]}
			onClick={() => {
				setCollapsed((open) => !open);
			}}
		/>
	);
}
export default CollapseToggle;
