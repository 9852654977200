import Alert from '@material-ui/lab/Alert';

import ComponentOutlet from '../storyblok/ComponentOutlet';

function SnackAlert({ body, variant, ...props }) {
	return (
		<Alert elevation={6} variant="filled" severity={variant} {...props}>
			<ComponentOutlet components={body} />
		</Alert>
	);
}
export default SnackAlert;
