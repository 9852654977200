import { useContext, useState, useEffect } from 'react';

import get from 'lodash/get';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import Form, { FormContext } from '../helper/Form';
import { FormGroupNameContext } from '../helper/FormGroupName';

import ComponentOutlet from '../storyblok/ComponentOutlet';
import StoryblokData, { StoryblokDataCtx } from '../storyblok/StoryblokData';
import stringifyCircular from '../../utils/stringifyCircular';

export function FormProvider({ name, children }) {
	const formData = useContext(FormContext);
	return <StoryblokData data={{ name: formData }}>{children}</StoryblokData>;
}

const DEBOUNCE_TIME = 500;
function LocalStorageForm({ body, name, initialState, ...props }) {
	const [subject] = useState(new BehaviorSubject());
	const sbData = useContext(StoryblokDataCtx);
	const init = initialState === '_all' ? sbData : get(sbData, initialState);

	useEffect(() => {
		const sub = subject.pipe(debounceTime(DEBOUNCE_TIME)).subscribe((state) => {
			const newVal = stringifyCircular(state);
			const oldVal = localStorage.getItem(name);
			if (newVal === oldVal) return;
			localStorage.setItem(name, newVal);
		});
		return () => sub.unsubscribe();
	}, [name, subject]);

	return (
		<Form {...props} initialState={init} needsValidation onForm={subject.next} validateOnSubmit>
			<FormGroupNameContext.Provider value={{}}>
				<FormProvider name={name}>
					<ComponentOutlet components={body} />
				</FormProvider>
			</FormGroupNameContext.Provider>
		</Form>
	);
}
export default LocalStorageForm;
