import { lazy } from 'react';
const manifest = {
	Conditional: require('./Conditional').default,
	ConditionalSwitch: require('./ConditionalSwitch').default,
	BasicData: require('./BasicData').default,
	BasicRepeater: require('./BasicRepeater').default,
	Range: require('./Range').default,
	Loader: lazy(() => import('./Loader')),
	PollLoader: lazy(() => import('./PollLoader')),
};

export default manifest;
