import { createContext, useContext } from 'react';

export const ContainerRefContext = createContext();
function ContainerRef({ containerRef, children }) {
	const container = useContext(ContainerRefContext);

	return (
		<ContainerRefContext.Provider value={container || containerRef}>
			{children}
		</ContainerRefContext.Provider>
	);
}
export default ContainerRef;
