import { createContext, useContext } from 'react';
import Loader from '../helper/Loader';
import get from 'lodash/get';
import filter from 'lodash/filter';
import { Switch, Route, useLocation, useRouteMatch } from 'react-router-dom';

import { getLinks } from '../../api/storyblok';
import StoryblokPage from './StoryblokPage';
import LoginGuard from './LoginGuard';
import OrgDetector from './OrgDetector';

export const StoryblokRoutesCtx = createContext({});
export function StoryblokRoute() {
	const { routesMap } = useContext(StoryblokRoutesCtx);
	const { pathname } = useLocation();
	const { path: rPath } = useRouteMatch();
	const path = pathname.length < 2 ? 'index' : pathname.substr(1);

	const pagePath = routesMap[path] || routesMap[rPath.substr(1)] || path;

	return (
		<LoginGuard>
			<OrgDetector>
				<StoryblokPage page={pagePath} />
			</OrgDetector>
		</LoginGuard>
	);
}

function filterRoutes({ slug, is_folder }) {
	return !is_folder;
}

function StoryblokRoutes() {
	return (
		<Loader services={() => [getLinks()]}>
			{({ results: [{ data }], reload }) => {
				const links = get(data, 'links');
				const matchingSlugs = filter(links, filterRoutes);

				const routes = matchingSlugs
					.reduce((links, link) => {
						const route = link.slug.replace(/\/-/g, '/:').replace(/^-/, ':');
						if (/index$/i.test(route)) {
							const newRoute = route.replace(/index$/i, '');
							links.push({ ...link, route: newRoute, exact: true });
						}
						links.push({ ...link, route });
						return links;
					}, [])
					.sort(({ route: routea }, { route: routeb }) => {
						const a = String(routea).split('/').filter(Boolean).length + !routea.includes(':');
						const b = String(routeb).split('/').filter(Boolean).length + !routeb.includes(':');
						return -1 * (a - b);
					});

				const routesMap = routes.reduce(
					(obj, { slug, route }) => Object.assign(obj, { [route]: slug }),
					{}
				);
				return (
					<StoryblokRoutesCtx.Provider value={{ links, routes, routesMap, reload }}>
						<Switch>
							{routes.map(({ slug, route, exact }) => (
								<Route path={`/${route}`} exact={Boolean(exact)} component={StoryblokRoute} />
							))}
							<Route component={StoryblokRoute} />
						</Switch>
					</StoryblokRoutesCtx.Provider>
				);
			}}
		</Loader>
	);
}

export default StoryblokRoutes;
