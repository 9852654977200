import { useContext, useEffect } from 'react';
import classnames from 'classnames';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import concat from 'lodash/concat';
import BsNavDropdown from 'react-bootstrap/NavDropdown';
import NavLink from 'react-bootstrap/NavLink';
import NavItem from 'react-bootstrap/NavItem';
import BsDropdown from 'react-bootstrap/Dropdown';

import StoryblokData, { StoryblokDataCtx } from '../storyblok/StoryblokData';
import ComponentOutlet from '../storyblok/ComponentOutlet';
import useToggle from '../hover/Toggle';

function convertLinks(cmp) {
	if (cmp.component === 'link') cmp.as = BsNavDropdown.Item;
	if (cmp.component === 'button') cmp.as = BsNavDropdown.Item;
	if (cmp.component === 'modal') cmp.as = BsNavDropdown.Item;
	if (cmp.component === 'side-drawer') cmp.as = BsNavDropdown.Item;
	if (cmp.component === 'app-link') cmp.as = BsNavDropdown.Item;
	return cmp;
}

function Dropdown({
	body,
	className,
	name,
	role,
	rootCloseEvent,
	state,
	text,
	title,
	drop,
	...props
}) {
	const sbData = useContext(StoryblokDataCtx);
	const events = get(sbData, 'agGrid.colDef._events');
	const stateProps = keyBy(state);
	const toggle = useToggle();
	const cmps = concat(body).map(convertLinks);
	const bsTitle = get(title, 'length') ? <ComponentOutlet components={title} /> : name;

	const cls = classnames(className, text, 'd-flex align-items-center', {
		'no-chevron': !stateProps.chevron,
	});

	useEffect(() => {
		if (!events) return;
		const sub = events.subscribe(function (event) {
			if (event.type !== 'cellClicked') return;
			const rowData = get(sbData, get(sbData, 'agGrid.colDef._name'));
			const columnId = get(sbData, 'agGrid.colDef._uid');
			if (event.colDef._uid !== columnId) return;
			if (!rowData || event.data !== rowData) return;
			toggle.show();
		});
		return () => sub.unsubscribe();
	}, [events, sbData, toggle]);

	return (
		<BsDropdown
			onToggle={toggle.toggle}
			show={toggle.shown}
			{...stateProps}
			drop={drop || undefined}
			as={NavItem}
		>
			<BsDropdown.Toggle {...props} className={cls} eventKey={null} as={NavLink}>
				{bsTitle}
			</BsDropdown.Toggle>

			<StoryblokData data={{ dropdown: true }}>
				<BsDropdown.Menu role={role} rootCloseEvent={rootCloseEvent}>
					<ComponentOutlet components={cmps} />
				</BsDropdown.Menu>
			</StoryblokData>
		</BsDropdown>
	);
}

Dropdown.defaultProps = {
	body: [],
};
export default Dropdown;
