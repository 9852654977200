import axios from 'axios';

import cache from '../utils/cache';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';

export const debugMode =
	window.location.href.includes('_storyblok=') || window.location.href.includes('localhost');
export const DEBOUNCE_TIME = debugMode ? 0 : 9 * 1000;
export const CDN_TIME = 15 * 60 * 1000;
export const version = debugMode ? 'draft' : 'published';

export const websiteKeys: any = {
	none: 'ZB7Fe9LW30zeYkmF8AW9hAtt',
	ecombalance: 'ZB7Fe9LW30zeYkmF8AW9hAtt',
	accountsbalance: 'kLQbeQ8KyINPrizrOzWyVQtt',
	trioseo: 'gJl5v86TLPQU4V16dsrW7wtt',
	outsourceschool: 'Pe1Be38omZKw6q7RxIOJcwtt',
};

export const website = window.location.host.split('.').slice(-2)[0];
export const token =
	localStorage.sbToken ||
	websiteKeys[localStorage.website] ||
	websiteKeys[window.location.host] ||
	websiteKeys[website] ||
	websiteKeys.none;

// cv=1634567747
// token=ZB7Fe9LW30zeYkmF8AW9hAtt
// version=published
export const storyblok = axios.create({ baseURL: '//api.storyblok.com/v2/cdn/' });

export function _getVersion() {
	const cv = debugMode ? Number(new Date()) : Math.round(+new Date() / CDN_TIME).toString(36);
	const params = { token, cv };
	return axios
		.get('//api.storyblok.com/v2/cdn/spaces/me', { params })
		.then((resp) => get(resp, 'data.space.version'));
}
export const getVersion = cache(_getVersion, CDN_TIME || DEBOUNCE_TIME);

storyblok.interceptors.request.use(function (config) {
	return getVersion().then((cv: string) => {
		config.params = { token, cv, version, ...config.params };
		return config;
	});
});

export async function get404Page(page: string = 'sys/404') {
	const pages = await getPages();
	return pages[page] || pages['sys/404'];
}

const getPages = cache(_getPages, DEBOUNCE_TIME);
export function _getPages() {
	const url = `/stories`;
	return storyblok
		.get(url, { params: { per_page: 100 } })
		.then((pages) => keyBy((pages?.data as any)?.stories, 'full_slug'))
		.then(cloneDeep)
		.catch((e) => get404Page());
}

export function getLinks(): Promise<any> {
	const url = `/links/`;
	return storyblok.get(url, { params: { per_page: 100 } }).then(cloneDeep);
}

export async function _getPage(page: string): Promise<any> {
	const pages = await getPages();
	return pages[page] || pages['sys/404'];
}

export default cache(_getPage, DEBOUNCE_TIME);
