import { createContext, useEffect, useContext, useState } from 'react';
import { zip, from } from 'rxjs';

import isFunction from 'lodash/isFunction';
import flatten from 'lodash/flatten';

import CircularProgress from '@material-ui/core/CircularProgress';

import { isSubscribable } from '../../utils/rxjs_utils';
import { withCatchError } from './CatchError';
import Timer from './Timer';
// export interface LoaderProps {
// 	children: Function;
// 	required: any[];
// 	initialValue: any;
// 	indicator: Function;
// 	onError: Function;
// 	services: Observable<any>[];
// }

// export interface LoaderResponse {
// 	error?: Error;
// 	reload: Function;
// 	results: Observable<any>[];
// 	subscription: Subscription;
// }

export const LoaderCtx = createContext({});
export const Indicator = () => <CircularProgress />;
function Loader({
	children,
	indicator,
	initialValue,
	observe,
	onError,
	progress,
	required,
	retries,
	services,
	unmountOnExit,
}) {
	const [results, setResults] = useState(initialValue);
	const [error, setError] = useState(null);
	const [subscription, setSubscription] = useState();
	const loaderConfig = useContext(LoaderCtx);

	function unsubscribe() {
		if (subscription) subscription.unsubscribe();
	}

	function reload(cb, count) {
		unsubscribe();
		try {
			setError(null);
			if (unmountOnExit) setResults(null);
		} catch (e) {}

		if (observe) {
			const sub = observe.subscribe(
				(res) => {
					setResults(res);
					return isFunction(cb) && cb(res);
				},
				(err) => {
					// if (+retries && count < +retries) reload(null, count + 1);
					setError(err);
				}
			);

			return setSubscription(sub);
		}

		const arraySafe = [].concat(services).map((srv) => srv());
		const serviceObs = flatten(arraySafe)
			.filter(isSubscribable)
			.map((s) => from(s));
		const sub = zip(...serviceObs).subscribe(
			(res) => {
				setResults(res);
				return isFunction(cb) && cb(res);
			},
			(err) => {
				// if (+retries && count < +retries) reload(null, count + 1);
				setError(err);
			}
		);

		setSubscription(sub);
	}

	useEffect(() => {
		if (initialValue && initialValue === results) return;
		reload();
		return unsubscribe;
	}, required);

	if (error && onError) return onError(error, reload);
	if (error && loaderConfig.onError && onError !== null) return loaderConfig.onError(error, reload);
	if (error && !onError)
		return children({ results: results || [], error, subscription, reload, setResults });
	if (results) return children({ results, subscription, reload, setResults });

	const indicatorFn = loaderConfig.indicator || indicator;
	return (
		<Timer time={loaderConfig.timeout || 2000}>
			{indicatorFn.$$typeof && indicatorFn}
			{!indicatorFn.$$typeof && indicatorFn({ progress })}
		</Timer>
	);
}

Loader.defaultProps = {
	children() {},
	indicator: <Indicator />,
	required: [],
	services: [],
	unmountOnExit: true,
};

export default withCatchError(Loader);
