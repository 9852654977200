import forIn from 'lodash/forIn';
import uniq from 'lodash/uniq';

function bsClasses(width, className) {
	const classList = new Set();
	if (!width) return classList;
	forIn(width, (v, k) => k.includes('left') && k.replace('left', 'start'));

	if (width.justify === 'container') classList.add('container');
	if (width.justify && width.justify !== 'container') classList.add('row');
	if (['container', 'row'].includes(width.justify)) width.justify = undefined;
	if (width.justify === 'grid') {
		classList.delete('container');
		classList.delete('row');
		classList.add('d-grid');
		width.justify = undefined;
	}

	if (width.allGap) {
		classList.add('d-grid');
	}

	if (width.justify) {
		classList.add('d-flex');
		classList.delete('row');
	}
	if (width.justify === 'center') classList.add('row');

	if (width.block_row) classList.add('block-row');

	if (className) {
		const classes = className.split(' ');
		uniq(classes)
			.filter(Boolean)
			.forEach((c) => classList.add(c));
	}

	let hidden = false;
	const hideCls = [
		`col-xs-${width.xsmall}`,
		`col-sm-${width.small}`,
		`col-md-${width.medium}`,
		`col-lg-${width.large}`,
		`col-xl-${width.xlarge}`,
	].map((item) => {
		// eslint-disable-next-line
		const [col, size, type] = item.split('-');
		if (hidden && type && type !== 'hide') {
			hidden = false;
			return `d-${size}-flex`;
		}
		if (!type) return null;
		hidden = type === 'hide';
		return null;
	});

	const classes = [
		`position-${width.position}`,
		`float-${width.float}`,
		`align-self-${width.align}`,
		`justify-content-${width.justify}`,

		`col-${width.xsmall}`,
		`col-lg-${width.large}`,
		`col-md-${width.medium}`,
		`col-sm-${width.small}`,
		`col-xl-${width.xlarge}`,
		width.xsmall === 'hide' && 'd-none',
		width.small === 'hide' && 'd-sm-none',
		width.medium === 'hide' && 'd-md-none',
		width.large === 'hide' && 'd-lg-none',
		width.xlarge === 'hide' && 'd-xl-none',

		width.xsmall === 'show' && 'd-flex',
		width.small === 'show' && 'd-sm-flex',
		width.medium === 'show' && 'd-md-flex',
		width.large === 'show' && 'd-lg-flex',
		width.xlarge === 'show' && 'd-xl-flex',

		width.shadow && width.shadow !== 'md' && `shadow-${width.shadow}`,
		width.shadow === 'md' && `shadow`,

		...hideCls,

		`col-xl-offset-${width.xlargeOffset}`,
		`offset-lg-${width.largeOffset}`,
		`offset-md-${width.mediumOffset}`,
		`col-sm-offset-${width.smallOffset}`,
		`col-offset-${width.xsmallOffset}`,

		`offset-xl-${width.xlargeOffset}`,
		`offset-lg-${width.largeOffset}`,
		`offset-md-${width.mediumOffset}`,
		`offset-sm-${width.smallOffset}`,
		`offset-${width.xsmallOffset}`,

		`p-${width.allPadding}`,
		`px-${width.xPadding}`,
		`py-${width.yPadding}`,
		`pt-${width.topPadding}`,
		`pe-${width.rightPadding}`,
		`pb-${width.bottomPadding}`,
		`ps-${width.leftPadding}`,

		`m-${width.allMargin}`,
		`mx-${width.xMargin}`,
		`my-${width.yMargin}`,
		`mt-${width.topMargin}`,
		`me-${width.rightMargin}`,
		`mb-${width.bottomMargin}`,
		`ms-${width.leftMargin}`,

		`gap-${width.allGap}`,
	].filter((c) => c && c.substr(-1) !== '-' && !c.includes('-undefined'));

	uniq(classes)
		.filter(Boolean)
		.forEach((c) => classList.add(c));

	return classList;
}

export default bsClasses;
