import Promise from 'bluebird';
import axios from 'axios';

import get from 'lodash/get';
import set from 'lodash/set';
import forIn from 'lodash/forIn';
import range from 'lodash/range';
import template from './template';

export const chunkSize = Number(localStorage.getItem('uploadChunkSize') || 1048380) | 0;
export default function chunkUpload({ name, setProgress, setService, sbData, url, method, data }) {
	return function onDrop([_file]) {
		const endpoint = template(url, sbData);
		if (!endpoint || !name) return;

		const formData = new FormData();
		const mappedData = {};
		forIn(get(data, 'options'), ({ name: k, value: v }) => set(mappedData, k, template(v, sbData)));
		forIn(mappedData, (v, k) => formData.append(k, v));

		formData.append(name, _file);

		if (url !== 'chunk') {
			const srv = axios[method](endpoint, formData, {
				headers: { 'Content-Type': 'multipart/form-data' },
				onUploadProgress(progressEvent) {
					setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
				},
			});
			return setService(srv);
		}

		const srv = getFileContext(_file);

		async function getFileContext() {
			const ext = _file.name.split('.').pop();
			const chunkPeice = _file.size / chunkSize;
			const count = _file.size % chunkSize === 0 ? chunkPeice : Math.floor(chunkPeice) + 1;
			const chunks = range(0, count);

			const response = await axios.post('/api/asset/chunks/start', { ext, filename: _file.name });
			const fileName = get(response, 'data.filename');
			const headers = { 'X-File-Name': fileName };
			const resp = await Promise.each(chunks, uploadChunk)
				.then(uploadCompleted)
				.then(pollTillFinished);
			return resp;

			function uploadChunk(index) {
				const prct = (index / count) * 100;
				setProgress(prct | 0);

				const start = index * chunkSize;
				const end = start + chunkSize;
				const chunk = _file.slice(start, end);
				const formData = new FormData();
				formData.append('chunk', chunk);

				const attempt = (e) => Promise.delay(e && 2000).then(submit);
				const submit = () => axios.post('/api/asset/chunks/upload', formData, { headers });

				return attempt().catch(attempt).catch(attempt).catch(attempt).catch(attempt).catch(attempt);
			}

			function uploadCompleted() {
				return axios.post('/api/asset/chunks/complete', {}, { headers });
			}

			async function pollTillFinished() {
				let data;
				while (!data) {
					data = await checkStatus();
				}

				return { data };
			}
			async function checkStatus() {
				const resp = await axios.get('/api/session?cache=none');
				const session = get(resp, 'data') || {};
				const file = session[fileName];
				if (!file) return await Promise.delay(1000);

				const progress = get(file, 'progress');
				if (!progress) return file;

				setProgress(progress);
				await Promise.delay(1000);
			}
		}

		setService(srv);
	};
}
