import { lazy } from 'react';

const manifest = {
	HelperHint: lazy(() => import('./HelperHint')),
	HelperStarter: lazy(() => import('./HelperStarter')),
	Helper: lazy(() => import('./Helper')),
	Layout: require('./Layout').default,
	LayoutContent: require('./LayoutContent').default,
	'default-page': require('./Page').default,
	page: require('./Page').default,
	Page: require('./Page').default,
	BlankPage: require('./BlankPage').default,
	Redirect: require('./Redirect').default,
	Story: require('./Story').default,
	TextBlock: require('./TextBlock').default,
};

export default manifest;
