import { forwardRef, useContext, useEffect, useState } from 'react';
import classnames from 'classnames';
import FormControl from 'react-bootstrap/FormControl';

import memoize from 'lodash/memoize';
import debounce from 'lodash/debounce';
import concat from 'lodash/concat';
import get from 'lodash/get';
import isBoolean from 'lodash/isBoolean';

import { FormContext } from './Form';
import { FormGroupContext } from './FormGroup';
const debounceChange = memoize(() => debounce((args, onChange) => onChange(...args), 100));

function InputFn(fwdProps, ref) {
	const {
		as: asInput,
		children,
		className,
		disabled,
		onChange: propOnChange,
		price,
		type,
		use,
		useId,
		validations,
		value,
		...props
	} = fwdProps;
	const formState = useContext(FormContext);
	const groupState = useContext(FormGroupContext);

	const disable = disabled || formState.submitting;
	const name = groupState.name || groupState.controlId || props.name;
	const values = concat(get(formState.form, name)).map((v) => (isBoolean(v) ? String(v) : v));
	const isCheckbox = ['checkbox', 'radio', 'switch'].includes(type);
	const isRadio = ['radio'].includes(type);
	const id = groupState.controlId;
	const Input = isCheckbox ? 'input' : DebouncedInput;
	const error = groupState.error || formState.errors[name];
	const selected = isCheckbox && values.includes(value);
	const registryName = type === 'checkbox' && useId !== false ? id : name;
	useEffect(() => groupState.setSelected && groupState.setSelected(selected), [selected]);
	useEffect(() => {
		value && !isCheckbox && formState.setField(registryName, value);
	}, [value]);
	useEffect(() => formState.register(registryName, validations), [registryName, validations]);
	useEffect(() => () => formState.unregister(registryName), []);

	function onChange(event) {
		const target = event.target || { name, value: event };
		let value = target.value;
		if (isCheckbox && !isRadio) {
			const valueSet = new Set(values);
			if (target.checked) valueSet.add(value);
			if (!target.checked) valueSet.delete(value);
			value = Array.from(valueSet).filter((v) => v != null);
		}

		const isSelect = event.target.tagName === 'SELECT';
		const option = isSelect && event.target.querySelector(`[value="${value}"]`);
		if (option) formState.setTotal(name, option.dataset.price * 100);

		if (price) {
			const name = isCheckbox ? `${target.name}__${target.value}` : target.name;
			formState.setTotal(name, price * 100);

			if (isCheckbox && !target.checked) formState.setTotal(name, 0);
			if (type === 'number') formState.setTotal(name, value * 100 * (price || 1));
		}

		formState.setField(target.name, value);
		formState.validate(registryName, value, validations);
		propOnChange && propOnChange(event);
	}

	const listeners = { ...props, [use]: onChange };
	if (Input === FormControl) listeners.onBlur = onChange;
	if (Input === FormControl) listeners.onInput = onChange;

	return (
		<Input
			as={asInput}
			disabled={disable}
			id={id}
			name={name}
			type={type}
			formValue={get(formState.form, name)}
			value={isCheckbox ? value : get(formState.form, name)}
			checked={isCheckbox ? values.includes(value) : undefined}
			{...listeners}
			className={classnames(className, {
				'is-invalid': formState.needsValidation && error,
			})}
			ref={ref}
		>
			{children}
		</Input>
	);
}

const doNotDebounce = ['username', 'password'];
function DebouncedInput({ onChange, value, as, formValue, ...props }) {
	const Input = as || FormControl;
	const [initalValue] = useState(props.value || formValue);

	if (initalValue) return <Input {...{ ...props, onChange, value }} />;
	if (doNotDebounce.includes(props.name)) return <Input {...{ ...props, onChange, value }} />;

	const changeMade = (...args) => debounceChange(args[0]?.target?.name)(args, onChange);

	// If there is no initial value this field can be debounced.
	return (
		<Input
			onChange={changeMade}
			{...props}
			onInput={changeMade}
			onBlur={changeMade}
			onAnimationStart={changeMade}
		/>
	);
}

const Input = forwardRef(InputFn);

Input.defaultProps = {
	use: 'onChange',
};

export default Input;
