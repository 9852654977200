const manifest = {
	...require('./ag-grid/manifest').default,
	...require('./bootstrap/manifest').default,
	...require('./ckeditor/manifest').default,
	...require('./data/manifest').default,
	...require('./form/manifest').default,
	...require('./material-ui/manifest').default,
	...require('./plaid/manifest').default,
	...require('./storyblok/manifest').default,
};

export default manifest;
