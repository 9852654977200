import { createContext, useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router';

export const LeaveMessageCtx = createContext([]);
function LeaveMessage({ children }) {
	const [leaveMessage, setLeaveMessage] = useState();
	const [safeRoute, setSafeRoute] = useState();
	const history = useHistory();

	useEffect(() => {
		history.listen(() => {
			setSafeRoute(null);
			setLeaveMessage(null);
		});
		// eslint-disable-next-line
	}, []);

	return (
		<LeaveMessageCtx.Provider value={{ leaveMessage, setLeaveMessage, safeRoute, setSafeRoute }}>
			{!safeRoute && leaveMessage && <Prompt when={leaveMessage} message={leaveMessage} />}
			{children}
		</LeaveMessageCtx.Provider>
	);
}
export default LeaveMessage;
