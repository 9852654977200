import { useContext } from 'react';
import range from 'lodash/range';

import ComponentOutlet from '../storyblok/ComponentOutlet';
import StoryblokData, { StoryblokDataCtx } from '../storyblok/StoryblokData';
import template from '../../utils/template';

function Range({ body, start, end, step, name }) {
	const $data = useContext(StoryblokDataCtx);
	const startTpl = Number(template(start, $data));
	const endTpl = Number(template(end, $data));
	const stepTpl = Number(template(step, $data)) || 1;

	return range(startTpl, endTpl, stepTpl).map((n, index) => (
		<StoryblokData key={n} data={{ [name]: n, index, ...$data }}>
			<ComponentOutlet components={body} prefix={n} />
		</StoryblokData>
	));
}

export default Range;
