import { useCallback, useContext } from 'react';
import axios from 'axios';
import get from 'lodash/get';
import once from 'lodash/once';

import template from '../../utils/template';
import ComponentOutlet from '../storyblok/ComponentOutlet';
import { StoryblokDataCtx } from '../storyblok/StoryblokData';
import { FormContext } from '../helper/Form';
import { memoize } from 'lodash';

const loadPlaid = once(() => {
	var $script = document.createElement('script');
	$script.type = 'text/javascript';
	$script.src = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js';

	var $head = document.getElementsByTagName('head')[0];
	$head.append($script);

	return $script;
});

const getHandler = memoize(
	(config) => window.Plaid.create(config),
	(config) => config.token
);
function usePlaid(config) {
	if (!window.Plaid) return {};
	return getHandler(config);
}

loadPlaid();
function PlaidLink({ body, url, method, path }) {
	const formState = useContext(FormContext);
	const sbData = useContext(StoryblokDataCtx);
	const token = get(sbData, template(path, sbData));
	const endpoint = template(url, sbData);

	const onSuccess = useCallback((public_token, metadata) => {
		const organizationId = get(sbData, 'organization.id');
		const payload = { public_token, metadata, organizationId };
		const name = endpoint.replace('form.', '');
		if (endpoint.includes('form.')) return formState.setField(name, payload);

		return axios[method](endpoint, payload);
	}, []);

	// const { open, ready } = usePlaidLink({ token, onSuccess, selectAccount: true });
	const { open } = usePlaid({ token, onSuccess, selectAccount: true });
	return <ComponentOutlet components={body} onClick={() => open()} />;
}
export default PlaidLink;
