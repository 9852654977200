import storyblok from './storyblok';

function autoCache() {
	// storyblok('index');
	// storyblok('layouts/default');
	// storyblok('layouts/blank');
	// storyblok('layouts/partials/footer');
	// storyblok('layouts/partials/global-search');
	// storyblok('layouts/partials/logo');
	// storyblok('layouts/partials/navigation-bar');
	// storyblok('layouts/partials/side-nav-links');
	// storyblok('layouts/partials/user-dropdown');
	storyblok('sys/error-messages');
	// storyblok('sys/success-messages');

	return null;
}

export default autoCache;
